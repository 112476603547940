<template>
  <v-app>
 
    <v-main>
      <router-view/>
    </v-main>
    <v-footer color="#222833" dark height="40px">
    <v-layout justify-center>
        <span>ecam.sk 2023</span>
    </v-layout>
</v-footer>  
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
